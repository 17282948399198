import React from 'react'
import Zoom from 'react-reveal/Slide';

const Expertise = ({image, title, children}) => {
    return (
        <Zoom>
            <div className="element">
                <div className="expImage">
                    <img src={image} alt="Expertises" />
                </div>
                <div className="expText">
                    <h2>{title}</h2>
                    <div>{children}</div>
                </div>
            </div>
        </Zoom>
    )
}

export default Expertise
