import React from 'react';
import Header from './components/Header';
import Logo from './components/Logo';
import study from './img/study2.jpg'
import handshake_lobby from './img/handshake_lobby.jpg'
import formation from './img/formation.jpg'
import communication from './img/communication.png'

import { Menu, Item } from './components/Menu'
import { ScrollingProvider, Section } from "react-scroll-section";
import SectionContainer from './components/SectionContainer';
import ExpertiseContainer from './components/ExpertiseContainer';
import Slider from './components/Slider';
import clients from './img/clients_rect.png'
import clients_resize from './img/clients_carre.png'
import Expertise from './components/Expertise';
import Contact from './components/Contact';
import Carousel3d from './components/Carousel3d'
import SocialMediaIcon from './components/SocialMediaIcon'

import './App.css';
import './Carousel.scss';
import './Bilanges.scss';
import Footer from './components/Footer';

function App() {
  return (
    <ScrollingProvider scrollBehavior="smooth">
      <div className="container-sm">
          <Header logo={<Logo/>} 
                  menu={<Menu>
                          <Item section="expertises"><a href="#expertises" data-toggle="collapse" data-target=".navbar-collapse.show">Expertises</a></Item>
                          <Item section="realisations"><a href="#realisations" data-toggle="collapse" data-target=".navbar-collapse.show">Réalisations</a></Item>
                          <Item section="clients"><a href="#clients" data-toggle="collapse" data-target=".navbar-collapse.show">Clients</a></Item>
                          <Item section="contact"><a href="#contact" data-toggle="collapse" data-target=".navbar-collapse.show">Contact</a></Item>
                        </Menu>}
          />
      <div className="row">
      <Section id="slider">
        <SectionContainer>
          <Slider />
        </SectionContainer>
      </Section>
      </div>

      <SocialMediaIcon/>

      <Section id="expertises">
      <div className="row exp">
        <SectionContainer>
          <ExpertiseContainer>
            <div className='about'>
              <h3>À PROPOS DE SBV CONSULTING</h3>
              <p>SBV CONSULTING est, au Congo Brazzaville, un acteur en Audit / Consulting dans les domaines
                de l'énergie et de l'environnement.
              </p>
            </div>
            <Expertise image={study} title="ÉTUDES DIVERSES">
              <li>Analyse, diagnostic et <b>rédaction de préconisations</b> dans les domaines de <a href="#realisations"><strong className="important">l'énergie</strong></a>, des mines et de l'industrie.</li>
              <li>Nous réalisons des <strong>études d'impact</strong> environnemental et social.</li>
            </Expertise>
            <Expertise image={formation} title="FORMATION" >
              <p>Nous assurons trois types de formation :</p>
              <li>L'accompagnement en responsabilité sociétales des entreprises (<b>RSE</b>).</li>
              <li>Sensibilisation aux normes <b>QHSE</b> : Qualité, Hygiène, Sécurité, Environnement.</li>
              <li><b>Coaching & Développement Personnel</b> : Atteignez vos objectifs.</li>
            </Expertise>
            <Expertise image={handshake_lobby} title="LOBBYING, ASSISTANCE AUX ENTREPRISES">
              <li>Nous vous accompagnons dans vos <b>démarches administratives</b> en jouant un rôle de <a href="#contact"><span className="important">facilitateur</span></a> auprès des administrations locales.</li>
            </Expertise>
            <Expertise image={communication} title="COMMUNICATION" >
              <li>Communication d'entreprise et d'institution.</li>
              <li>Culture et patrimoine.</li>
              <a href="#realisations"><strong className="important"><li>Conférence à thème.</li></strong></a>
            </Expertise>
          </ExpertiseContainer>
        </SectionContainer>
      </div>
      </Section>

      <Section id="realisations">
      <div className="row">
        <SectionContainer>
          <div className="carousel">
            <div className="col-12">
              <Carousel3d/>
            </div>
          </div>
        </SectionContainer>
      </div>
      </Section>


      <Section id="clients">
        <SectionContainer>
            <picture>
              <source media="(max-width: 500px)" srcSet={clients_resize}/>
              <img className="clients" src={clients} alt="nos clients"/>
            </picture>
        </SectionContainer>
      </Section>

      <Section id="contact">
        <SectionContainer>
          <Contact/>
        </SectionContainer>
      </Section>

      <Footer />
      </div>
    </ScrollingProvider>
  );
}

export default App;
