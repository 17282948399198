import React from "react";
//import GoogleMapReact from 'google-map-react';
import carte from '../img/carte.png';

//const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap(){
  /*const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };*/

  return (
    // Important! Always set the container height explicitly
    <div style={{ marginLeft: '5%', height: '400px', width: '600px' }}>
      {/*       <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact>
      */}
      <img src={carte} alt="Emplacement sur la carte" style={{width: '100%', height: '100%'}}/>
    </div>
  );
}