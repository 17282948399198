import React from 'react'
import styled from "styled-components"
import { SectionLink } from "react-scroll-section"



    const Menu = ({children}) => {
        return (
            <ul className="nav navbar-nav">
                {children}
            </ul>
        )
    }

    const Item = styled.li`
        cursor: pointer;
        border-top: ${props =>
            props.selected ? "#046D35" : "transparent"} 3px solid;`;

    function MenuItem({children, section}) {

    return (

        <SectionLink section={section}>
            {link => (
                <Item onClick={link.onClick} selected={link.isSelected}>{children}</Item>
            )}
        </SectionLink>
        
    )
    }

    export { MenuItem as Item, Menu };

    export default styled;